import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';

import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import BreederProfileCurrentLittersSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileCurrentLittersSectionSkeleton';
import Button from 'dpl/common/design_system/Button';
import BreederProfileLitterPuppyCard from 'dpl/components/BreederProfile/Show/BreederProfileLitterPuppyCard';

import useLitters from '../hooks/useLitters';

const COLLAPSED_PUPPIES_COUNT = 5;
const RESULT_GROUP_SIZE = 4;

function PuppiesListItem({ position, allPuppies, puppy, breederProfileId }) {
  const trackInView = useCallback(() => {
    if (position % RESULT_GROUP_SIZE === 1) {
      const idx = position - 1;
      const resultGroup = allPuppies.slice(idx, idx + RESULT_GROUP_SIZE);

      sendRudderstackEvent(
        ANALYTICS_EVENTS.SEARCH_RESULT_GROUP_VIEWED_BY_USER,
        {
          view: ANALYTICS_VIEWS.BREEDER_PROFILE,
          view_feature: ANALYTICS_VIEW_FEATURES.PUPPY_CARD,
          view_feature_location:
            ANALYTICS_VIEW_FEATURE_LOCATIONS.AVAILABLE_PUPPIES,
          results: resultGroup.map((result, puppyIdx) => ({
            dog_id: result.id,
            puppy_name: result.name,
            breed_id: result.breed_id,
            breeder_profile_id: breederProfileId,
            position: position + puppyIdx
          }))
        }
      );
    }
  }, [position, allPuppies, breederProfileId]);

  return (
    <InView
      triggerOnce
      onChange={inView => {
        if (inView) trackInView();
      }}
    >
      <li>
        <BreederProfileLitterPuppyCard
          puppy={puppy}
          breederProfileId={breederProfileId}
          analytics={{
            position,
            view_feature_location:
              ANALYTICS_VIEW_FEATURE_LOCATIONS.AVAILABLE_PUPPIES
          }}
        />
      </li>
    </InView>
  );
}

PuppiesListItem.propTypes = {
  position: PropTypes.number.isRequired,
  allPuppies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  puppy: PropTypes.shape({}).isRequired,
  breederProfileId: PropTypes.number.isRequired
};

export default function PuppiesList() {
  const { breederProfileId } = useSelector(({ server }) => server);
  const { isFetchingCurrentLitters, sortedAvailablePuppies } = useLitters();

  const isCollapsible = sortedAvailablePuppies.length > COLLAPSED_PUPPIES_COUNT;
  const [isCollapsed, toggleIsCollapsed] = useReducer(
    collapsed => !collapsed,
    () => !isCollapsible
  );

  if (isFetchingCurrentLitters) {
    return <BreederProfileCurrentLittersSectionSkeleton />;
  }

  if (sortedAvailablePuppies.length === 0) {
    return null;
  }

  const listings = isCollapsed
    ? sortedAvailablePuppies.slice(0, COLLAPSED_PUPPIES_COUNT)
    : sortedAvailablePuppies;

  return (
    <div className="PuppiesList container-xxl flex flex-column pb6">
      <h3 className="mb6 font-24 lh-title">Available puppies</h3>
      <ul className="grid gap-24">
        {listings.map((puppy, idx) => (
          <PuppiesListItem
            key={puppy.id}
            position={idx + 1}
            allPuppies={sortedAvailablePuppies}
            puppy={puppy}
            breederProfileId={breederProfileId}
          />
        ))}
      </ul>

      {isCollapsible && (
        <Button
          className="font-16 stone-700 w-100 mt4"
          variant="outline"
          size="default"
          onClick={toggleIsCollapsed}
        >
          {isCollapsed ? 'See more puppies' : 'See less puppies'}
        </Button>
      )}
    </div>
  );
}
